.kandiPageContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.kandiPageContentContainer {
    border-color: rgb(0, 242, 255);
    border-width: 2px;
    border-style: dotted;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.kandiPagePhoto {
    border-radius: 8px;
    border: 1px solid #ddd;
    padding: 5px;
    width: 300px;
    height: auto;
}