/* The Modal (background) */
.sign-in-modal {
    display: block;
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
    text-align: center;
  }
  
  /* Modal Content/Box */
  .sign-in-modal-content {
    background-color: #fefefe;
    margin: 5% auto; /* 15% from the top and centered */
    padding: 20px;
    border: 1px solid #888;
    display: inline-block; // Size to content
    --container-heigh: none;
  }

  amplify-authenticator {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    height: 60vh;
  }
  
  /* The Close Button */
  .close {
    color: #aaa;
    font-size: 28px;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }