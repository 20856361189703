.userKandisContainer {
    border-color: gold;
    border-width: 5px;
    border-style: solid;
    width: fit-content;
    height: fit-content;
    margin: 15px auto 15px auto;
    padding: 10px;
}

.kandiContainer {
    border-color: purple;
    border-width: 2px;
    border-style: dotted;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.kandiPhoto {
    border-radius: 8px;
    border: 1px solid #ddd;
    padding: 5px;
    width: 150px;
    height: auto;
}

.addTag {
    width: 100px;
}