.createKandiContainer {
    border-color: blue;
    border-width: 5px;
    border-style: solid;
    width: fit-content;
    height: fit-content;
    margin: 15px auto 15px auto;
    padding: 10px;
}

.createKandiContainer form {
    display: flex;
    flex-direction: column;
    align-items: center;
}